import { motion } from "framer-motion";

const Footer = () => {
  return (
    <footer className="relative w-full bg-[#030303] text-white py-16">
      <div className="relative z-20 max-w-[2000px] mx-auto px-8 desktop:px-24 laptop:px-20 tablet:px-12 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1, ease: [0.16, 1, 0.3, 1] }}
          className="flex justify-between items-center border-t border-white/10 pt-8"
        >
          <p className="text-neutral-400">
            © 2024 Founders Inc. All rights reserved.
          </p>
          <p className="text-neutral-400">San Francisco, CA</p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
