import { Company } from "./types";

export const companies: Company[] = [
  { name: "Sync Labs", description: "AI lip sync", url: "https://sync.so/" },
  {
    name: "thirdweb",
    description: "The complete full stack web3 dev platform",
    url: "https://thirdweb.com/",
  },
  {
    name: "Lexica",
    description: "Make AI generated art",
    url: "https://lexica.art/",
  },
  {
    name: "Fluid",
    description: "The best web browser for VR",
    url: "https://fluid.so/",
  },
  {
    name: "Magical Toys",
    description: "The world's first toy that can chat in real time",
    url: "https://magical.toys/",
  },
  {
    name: "Unriddle",
    description: "Read, write and find papers faster",
    url: "https://unriddle.ai",
  },
  {
    name: "Neurosity",
    description: "Control your brainwaves to increase productivity",
    url: "https://neurosity.co/",
  },
  { name: "Sync Labs", description: "AI lip sync" },
  {
    name: "thirdweb",
    description: "The complete full stack web3 dev platform",
  },
  { name: "Lexica", description: "Make AI generated art" },
  { name: "Fluid", description: "The best web browser for VR" },
  {
    name: "Magical Toys",
    description: "The world's first toy that can chat in real time",
  },
  { name: "Unriddle", description: "Read, write and find papers faster" },
  {
    name: "Neurosity",
    description: "Control your brainwaves to increase productivity",
  },
];
