import { motion } from "framer-motion";

const LocationHeader = () => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 0.3 }}
    className="flex items-center space-x-4 mb-24 desktop:mb-32 laptop:mb-24 tablet:mb-20 mobile:mb-16"
  >
    <div className="w-32 h-[1px] bg-gradient-to-r from-neutral-500 to-neutral-700" />
    <span className="text-neutral-400 text-sm tracking-[0.25em] uppercase font-light">
      San Francisco
    </span>
  </motion.div>
);

export default LocationHeader;
