import { motion } from "framer-motion";

const Description = () => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 1,
      ease: [0.16, 1, 0.3, 1],
      delay: 0.9,
    }}
    className="max-w-3xl mb-24 desktop:mb-32 laptop:mb-24 tablet:mb-20 mobile:mb-16"
  >
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 1.2 }}
      className="text-xl desktop:text-2xl laptop:text-xl tablet:text-lg mobile:text-base text-neutral-400 leading-relaxed"
    >
      It's not just about getting your first check from us. We know deeply that
      what can make or break a founder isn't so much funding, MRR, PMF, etc
      —it's being resilient enough to fail and try again.
    </motion.p>
  </motion.div>
);

export default Description;
