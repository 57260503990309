import { motion } from "framer-motion";

const About = () => {
  return (
    <section
      id="about"
      className="relative w-full bg-[#030303] text-white py-32"
    >
      <div className="absolute top-0 left-0 right-0 h-32 bg-gradient-to-b from-[#030303] to-transparent" />
      <div className="relative z-20 max-w-[2000px] mx-auto px-8 desktop:px-24 laptop:px-20 tablet:px-12 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1.2, ease: [0.16, 1, 0.3, 1] }}
          className="max-w-4xl"
        >
          <p className="text-3xl desktop:text-4xl laptop:text-3xl tablet:text-2xl mobile:text-xl font-light leading-relaxed mb-12 text-neutral-300">
            Founders Inc is something that we wish we had ourselves when we
            started out.
          </p>
          <p className="text-xl desktop:text-2xl laptop:text-xl tablet:text-lg mobile:text-base text-neutral-400 leading-relaxed">
            So, we built a 15,000 sq ft space right in SF that you could work
            out of — alongside some of the best builders in the world.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
