import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";

interface UseCountUpProps {
  end: number;
  duration?: number;
  delay?: number;
}

const useCountUp = ({ end, duration = 2, delay = 0 }: UseCountUpProps) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(0);
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      gsap.to(countRef, {
        current: end,
        duration,
        delay,
        ease: "power2.out",
        onUpdate: () => {
          setCount(Math.round(countRef.current));
        },
      });
    }
  }, [inView, end, duration, delay]);

  return { count, ref };
};

export default useCountUp;
