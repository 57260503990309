import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const TypewriterText = ({ text }: { text: string }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayText(text.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [text]);

  return <span>{displayText}</span>;
};

const MainHeading = () => (
  <div className="space-y-12 mb-24 desktop:mb-32 laptop:mb-24 tablet:mb-20 mobile:mb-16">
    <motion.h1
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1,
        ease: [0.16, 1, 0.3, 1],
        delay: 0.5,
      }}
      className="font-serif text-[12vw] desktop:text-[10vw] laptop:text-[11vw] tablet:text-[12vw] mobile:text-[14vw] font-bold leading-[0.85] tracking-tight"
    >
      <TypewriterText text="Hi." />
    </motion.h1>

    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1,
        ease: [0.16, 1, 0.3, 1],
        delay: 0.7,
      }}
      className="max-w-4xl"
    >
      <p className="relative text-5xl desktop:text-6xl laptop:text-5xl tablet:text-4xl mobile:text-3xl font-light leading-[1.1] tracking-[-0.02em]">
        <span className="opacity-0">
          We're building something different for you to become a founder.
        </span>
        <span className="absolute top-0 left-0">
          <TypewriterText text="We're building something different for you to become a founder." />
        </span>
      </p>
    </motion.div>
  </div>
);

export default MainHeading;
