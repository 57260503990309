import { motion } from "framer-motion";
import { CompanyCardProps } from "./types";

export const CompanyCard: React.FC<CompanyCardProps> = ({ company, index }) => {
  return (
    <motion.a
      href={company.url}
      target="_blank"
      rel="noopener noreferrer"
      key={company.name}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1, duration: 0.5 }}
      className="block p-8 rounded-lg bg-white/5 backdrop-blur-sm border border-white/10 hover:bg-white/10 transition-colors duration-300"
    >
      <h3 className="text-xl font-medium mb-2">{company.name}</h3>
      <p className="text-neutral-400">{company.description}</p>
    </motion.a>
  );
};
