import { motion } from "framer-motion";
import BackgroundImage from "./components/BackgroundImage";
import GrainOverlay from "./components/GrainOverlay";
import MainContent from "./components/MainContent";
import GradientOrbs from "./components/GradientOrbs";
import SparkleEffect from "./components/SparkleEffect";

const Hero = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="relative min-h-screen w-full bg-[#030303] text-white overflow-hidden relative"
    >
      <GrainOverlay />
      <BackgroundImage />
      <SparkleEffect />
      <MainContent />
      <GradientOrbs />
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-[#030303] to-transparent" />
    </motion.section>
  );
};

export default Hero;
