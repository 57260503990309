import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Stats from "./Stats";

const CTASection = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 1.1 }}
    className="flex items-center space-x-20 desktop:space-x-24 laptop:space-x-20 tablet:space-x-16 mobile:flex-col mobile:space-x-0 mobile:space-y-12"
  >
    <Link
      to="/visit"
      className="group relative inline-flex items-center px-14 py-7 rounded-full text-lg font-medium overflow-hidden bg-white/5 backdrop-blur-sm border border-white/10 hover:border-white/20 transition-colors duration-300"
    >
      <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-500/10 to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
      <span className="relative z-10 text-white group-hover:text-white transition-colors duration-200">
        Come for a visit
      </span>
      <svg
        className="relative z-10 ml-4 w-5 h-5 text-white transition-all duration-200 transform group-hover:translate-x-1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
      </svg>
    </Link>
    <Stats />
  </motion.div>
);

export default CTASection;
