import { CompanyCard } from "./CompanyCard";
import { companies } from "./data";

export const CompaniesGrid: React.FC = () => {
  return (
    <div className="grid grid-cols-1 desktop:grid-cols-3 laptop:grid-cols-2 tablet:grid-cols-2 gap-8">
      {companies.map((company, index) => (
        <CompanyCard
          key={`${company.name}-${index}`}
          company={company}
          index={index}
        />
      ))}
    </div>
  );
};
