import { motion } from "framer-motion";
import { useEffect, useRef } from "react";

const Community = () => {
  const videoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      const muxPlayer = document.createElement("mux-player");
      muxPlayer.setAttribute(
        "playback-id",
        "8s1016RnsE77cIrhuRrfWWTi00UI2T4PVAZESX7eLJN00I",
      );
      muxPlayer.setAttribute("metadata-video-title", "Placeholder (optional)");
      muxPlayer.setAttribute(
        "metadata-viewer-user-id",
        "Placeholder (optional)",
      );
      muxPlayer.setAttribute("accent-color", "#FF0000");
      muxPlayer.setAttribute("autoplay", "");
      muxPlayer.setAttribute("muted", "");
      muxPlayer.setAttribute("playsinline", "");
      muxPlayer.style.width = "100%";
      muxPlayer.style.height = "100%";
      muxPlayer.style.borderRadius = "0.5rem";
      videoRef.current.appendChild(muxPlayer);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.innerHTML = "";
      }
    };
  }, []);

  return (
    <section className="relative w-full bg-[#030303] text-white py-32">
      <div className="relative z-20 max-w-[2000px] mx-auto px-8 desktop:px-24 laptop:px-20 tablet:px-12 mobile:px-6">
        <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-16">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: [0.16, 1, 0.3, 1] }}
            className="flex-1"
          >
            <h2 className="text-3xl desktop:text-4xl laptop:text-3xl tablet:text-2xl mobile:text-xl font-light mb-8">
              We're most proud of the community we've built for our founders.
            </h2>
            <p className="text-xl desktop:text-2xl laptop:text-xl tablet:text-lg mobile:text-base text-neutral-400 leading-relaxed mb-12">
              And a video is worth 10,000 words.
            </p>
            <p className="text-xl desktop:text-2xl laptop:text-xl tablet:text-lg mobile:text-base text-neutral-400 leading-relaxed">
              If this sounds interesting, let us know what you're working on. We
              would love to chat & invite you to our home.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: [0.16, 1, 0.3, 1], delay: 0.2 }}
            className="flex-1 aspect-video bg-white/5 rounded-lg overflow-hidden"
            ref={videoRef}
          />
        </div>
      </div>
    </section>
  );
};

export default Community;
