import { motion } from "framer-motion";

const CreateSiteSignature = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8, delay: 1.5 }}
      className="fixed bottom-8 right-8 z-50"
    >
      <a
        href="https://createsite.ai"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-2 px-4 py-2 rounded-full bg-white/5 backdrop-blur-sm border border-white/10 hover:bg-white/10 transition-colors duration-300"
      >
        <span className="text-sm text-neutral-400">
          Made with CreateSite.ai
        </span>
      </a>
    </motion.div>
  );
};

export default CreateSiteSignature;
