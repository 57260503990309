import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Companies from "../components/Companies";
import Community from "../components/Community";
import ImageSection from "../components/ImageSection";
import Footer from "../components/Footer";

const Home: React.FC = () => {
  return (
    <>
      {" "}
      <Hero />
      <About />
      <Companies /> <Community />
      <ImageSection />
      <Footer />
    </>
  );
};

export default Home;
