import useCountUp from "../../../../hooks/useCountUp";

const Stats = () => {
  const { count: spaceCount, ref: spaceRef } = useCountUp({ end: 15000 });
  const { count: companiesCount, ref: companiesRef } = useCountUp({
    end: 50,
    delay: 0.2,
  });

  return (
    <div className="flex items-center space-x-16 text-neutral-400">
      <div className="text-sm" ref={spaceRef}>
        <span className="block text-4xl font-bold text-white mb-2">
          {spaceCount.toLocaleString()}
        </span>
        <span className="uppercase tracking-wider">sq ft space</span>
      </div>
      <div className="w-[1px] h-20 bg-neutral-800" />
      <div className="text-sm" ref={companiesRef}>
        <span className="block text-4xl font-bold text-white mb-2">
          {companiesCount}+
        </span>
        <span className="uppercase tracking-wider">companies</span>
      </div>
    </div>
  );
};

export default Stats;
