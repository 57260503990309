import { motion } from "framer-motion";

const BackgroundImage = () => (
  <div className="absolute inset-0 z-0">
    <div className="absolute inset-0 bg-gradient-to-b from-black/98 via-black/95 to-black/98 z-10" />
    <motion.div
      initial={{ scale: 1.1 }}
      animate={{ scale: 1 }}
      transition={{ duration: 2, ease: [0.16, 1, 0.3, 1] }}
      className="h-full"
    >
      <img
        src="https://createsiteai.b-cdn.net/c/_/5340b080-3172-40a3-8e7e-7015c106f40d"
        alt="SF Office Space"
        className="w-full h-full object-cover object-center filter brightness-[0.15]"
      />
    </motion.div>
  </div>
);

export default BackgroundImage;
