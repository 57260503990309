import { motion } from "framer-motion";
import LocationHeader from "./LocationHeader";
import MainHeading from "./MainHeading";
import Description from "./Description";
import CTASection from "./CTASection";

const MainContent = () => (
  <div className="relative z-20 max-w-[2000px] mx-auto px-8 desktop:px-24 laptop:px-20 tablet:px-12 mobile:px-6">
    <div className="min-h-screen flex flex-col justify-center">
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: [0.16, 1, 0.3, 1] }}
        className="max-w-7xl"
      >
        <LocationHeader />
        <MainHeading />
        <Description />
        <CTASection />
      </motion.div>
    </div>
  </div>
);

export default MainContent;
