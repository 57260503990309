import { motion } from "framer-motion";

const ImageSection = () => {
  return (
    <section className="relative w-full bg-[#030303] text-white py-32">
      <div className="relative z-20 max-w-[2000px] mx-auto px-8 desktop:px-24 laptop:px-20 tablet:px-12 mobile:px-6">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1, ease: [0.16, 1, 0.3, 1] }}
        >
          <img
            src="https://createsiteai.b-cdn.net/c/_/94f26779-94e7-40dd-849c-5179bca11e3d"
            alt="Office Space"
            className="max-w-5xl w-full h-auto mx-auto rounded-lg"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default ImageSection;
